body, #root, .App {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.App {
  background-color: #000;
  &.dropping {
    background-color: #111;
  }
}
.Body, .BodyV {
  position: absolute;
  top: 0;
  bottom: 0;
  height: auto;
  left: calc(50% - 200vh / 3);
  width: calc(400vh / 3);
}
.Body .inner {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
@media (max-aspect-ratio: 640/480) {
  .Body {
    left: 0;
    right: 0;
    width: auto;
    top: calc(50% - 37.5vw);
    height: 75vw;
  }
  .BodyV {
    left: 0;
    right: 0;
    width: auto;
  }
}
.BodyV {
  pointer-events: none;
}

@media (min-aspect-ratio: 3/1) {
  .App.keyboard .Body .inner {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 75vw;
    height: 56.25vw;
    transform: translate(-50%, -70%);
  }
}

.App {
  canvas {
    width: 100%;
    height: 100%;
    cursor: none;
    visibility: hidden;
  }
  &.started canvas {
    visibility: visible;
  }
  .keyboard {
    position: absolute;
    pointer-events: auto;
    background: rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 0);
    border: none;
    outline: none;
    z-index: 100;
    display: none;
    cursor: none;
    &::selection {
      background: rgba(0, 0, 0, 0);
      color: rgba(0, 0, 0, 0);
      }
  }
  &.keyboard .keyboard {
    display: block;
  }

  .start, .error, .loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .start, .error {
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    pointer-events: auto;
  }

  .start {
    border: 1px solid #888;
    padding: 12px;
    color: #fff;
    font-size: 18px;
    text-align: center;
    background: #000;
    p {
      margin: 10px 0;
    }
    .startButton {
      display: block;
      margin-left: auto;
      margin-right: auto;
      border: 1px solid #fff;
      background: #000;
      font-size: 2em;
      padding: 4px 18px;
      cursor: pointer;
      margin-top: 6px;
      width: 8em;
      &:hover {
        background-color: #111;
      }
    }
    .saveList {
      border: 1px solid #fff;
      text-align: left;
      li {
        padding: 0 6px;
        .info {
          color: #888;
          margin-left: 6px;
        }
        .btnRemove {
          color: #800;
          &:hover {
            color: #f00;
          }
          float: right;
          cursor: pointer;
          margin: 0 4px;
        }
        .btnDownload {
          color: #888;
          &:hover {
            color: #fff;
          }
          float: right;
          cursor: pointer;
          margin: 0 4px;
        }
        &:hover {
          background-color: #444;
        }
      }
    }
  }
}

.App {
  .error {
    display: block;
    border: 1px solid #800;
    background: #300;
    padding: 12px;
    color: #f33;
    font-size: 18px;
    text-align: center;
    &:hover {
      text-decoration: none;
    }
    .header {
      font-weight: bold;
      font-size: 1.2em;
      margin-bottom: 0.3em;
    }
    .body {
      font-family: monospace;
    }
    .footer {
      margin-top: 0.5em;
      font-size: 0.8em;
    }
  }

  .loading {
    color: #888;
    font-size: 32px;
    text-align: center;
    width: 75%;
    .progressBar {
      display: block;
      position: absolute;
      margin-top: 16px;
      width: 100%;
      height: 40px;
      border: 2px solid #888;
      span {
        position: absolute;
        left: 3px;
        top: 3px;
        bottom: 3px;
        right: 3px;
      }
      span span {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: #888;
      }
    }
  }
  a, .link {
    display: inline-block;
    color: rgb(112, 112, 255);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    cursor: pointer;
  }
}

.App {
  .touch-ui {
    display: none;
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 25vh;
    .touch-button {
      position: absolute;
      left: 50%;
      background-color: #444;
      transform: translate(-50%, -50%);
      pointer-events: auto;
      &.active {
        background-color: #fff;
      }
      mask-size: 100% 100%;
    }
    .touch-button-0 {
      top: 16.7vh;
    }
    .touch-button-1 {
      top: 50vh;
    }
    .touch-button-2 {
      top: 83.3vh;
    }
  }
  .touch-ui.touch-mods {
    left: 0;
    .touch-button {
      width: 20vh;
      height: 20vh;
      border-radius: 10vh;
    }
    .touch-button-0 {
      mask-image: url(./icons/move.svg);
    }
    .touch-button-1 {
      mask-image: url(./icons/rmb.svg);
    }
    .touch-button-2 {
      mask-image: url(./icons/shift.svg);
    }
  }
  .touch-ui.touch-belt {
    right: 0;
    .touch-button {
      width: 14vh;
      height: 14vh;
      font-size: 1vh;
      border-radius: 20%;
      overflow: hidden;
      background-color: #000;
      border: 2px solid #444;
      canvas {
        position: absolute;
        left: 5%;
        top: 5%;
        width: 90%;
        height: 90%;
      }
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border-radius: 20%;
        box-shadow: inset 0 0 1em 1.5em #000;
      }
    }
  }
  &.touch .touch-ui {
    display: block;
  }
  &.touch.keyboard .touch-ui {
    display: none;
  }
}
@media (max-aspect-ratio: 880/480) {
  .App .touch-ui {
    width: calc(50vw - 200vh / 3);
  }
}
@media (max-aspect-ratio: 832/480) {
  .App .touch-ui {
    width: 20vh;
  }
}
@media (max-aspect-ratio: 640/480) {
  .App .touch-ui {
    width: auto;
    height: 20vw;
    .touch-button {
      top: 50%;
    }
    .touch-button-0 {
      left: 16.7vw;
    }
    .touch-button-1 {
      left: 50vw;
    }
    .touch-button-2 {
      left: 83.3vw;
    }
  }
  .App .touch-ui.touch-mods {
    top: auto;
    right: 0;
    .touch-button {
      width: 20vw;
      height: 20vw;
      border-radius: 10vw;
    }
  }
  .App .touch-ui.touch-belt {
    bottom: auto;
    left: 0;
    .touch-button {
      width: 14vw;
      height: 14vw;
      font-size: 1vw;
    }
  }
}
@media (max-aspect-ratio: 640/736) {
  .App .touch-ui {
    height: calc(50vh - 75vw / 2);
  }
}
@media (max-aspect-ratio: 640/800) {
  .App .touch-ui {
    height: 25vw;
  }
}
